
export const startVN = [
    {url: 'media/vn/kinhmoi.wav'},
  ]
  export const listVN = [
    {type:0, url:'media/vn/0.wav'},
    {type:1, url:'media/vn/1.wav'},
    {type:2, url:'media/vn/2.wav'},
    {type:3, url:'media/vn/3.wav'},
    {type:4, url:'media/vn/4.wav'},
    {type:5, url:'media/vn/5.wav'},
    {type:6, url:'media/vn/6.wav'},
    {type:7, url:'media/vn/7.wav'},
    {type:8, url:'media/vn/8.wav'},
    {type:9, url:'media/vn/9.wav'},
  ]
  export const endVN = [
    {url: 'media/vn/denquay.wav'},
  ]
  export const startEN = [
    {url: 'media/en/kinhmoi_E.wav'},
  ]
  export const listEN = [
    {type:0, url:'media/en/0_E.wav'},
    {type:1, url:'media/en/1_E.wav'},
    {type:2, url:'media/en/2_E.wav'},
    {type:3, url:'media/en/3_E.wav'},
    {type:4, url:'media/en/4_E.wav'},
    {type:5, url:'media/en/5_E.wav'},
    {type:6, url:'media/en/6_E.wav'},
    {type:7, url:'media/en/7_E.wav'},
    {type:8, url:'media/en/8_E.wav'},
    {type:9, url:'media/en/9_E.wav'},
  ]
  export const endEN = [
    {url: 'media/en/denquay_E.wav'},
  ]