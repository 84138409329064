import * as types from '../action/const'

const initialState = {
    status: null
}

export default function logout403Reducer(state = initialState, action) {
    switch (action.type) {
        case types.LOGOUT_LCD:
            return {
                ...state,
                status: 403
            }
        default:
            return state
    }
}