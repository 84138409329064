import * as types from '../action/const'

const initialState = {
    LcdID: '',
    OfficeName: '',
    OfficeID: '',
    err: null
}

export default function connectLcdReducer(state = initialState, action) {
    switch (action.type) {
        case types.CONNECT_LCD:

            return {
                ...state,
                LcdID: action.data.LcdID,
                OfficeName: action.data.OfficeName,
                OfficeID: action.data.OfficeID,
                err: null
            }
        case types.CONNECT_LCD_ERR:
            return {
                ...state,
                LcdID: '',
                OfficeName: '',
                OfficeID: '',
                err: action.err
            }
        default:
            return state
    }
}