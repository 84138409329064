import React, { Component } from "react";
import styled from "styled-components";

const Bound = styled.div`
	width: 432px;
	height: 280px;
	position: absolute;
	background: #fff;
	border-radius: 10px;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
	padding: 0px 50px;
	.title {
		font-family: SVN-Gilroy;
		font-size: 24px;
		line-height: 26px;
		color: #f31919;
		font-weight: bold;
	}
	.btn-confirm {
		background: #f31919;
		border-radius: 18px;
		width: 150px;
		height: 36px;
		cursor: pointer;
		border-radius: 18px;
		outline: none;
		border-width: initial;
		border-style: none;
		border-color: initial;
		border-image: initial;
		font-family: SVN-Gilroy;
		font-size: 14px;
		font-weight: bold;
		text-transform: uppercase;
		color: rgb(255, 255, 255);
	}
`;

export default class PopupRestoreData extends Component {
	onClick = () => {
		this.props.onClosePopup();
	};
	render() {
		return (
			<Bound>
				<div className="title">Khôi phục dữ liệu hệ thống</div>
				<p>
					Hệ thống ghi nhận phản hồi dữ liệu thất bại tại phiên làm
					việc hiện tại do một số dữ liệu đã bị thất lạc. Bạn vui lòng
					xác nhận lại việc khôi phục dữ liệu{" "}
				</p>
				<button className="btn-confirm" onClick={this.onClick}>
					KHÔI PHỤC DỮ LIỆU
				</button>
			</Bound>
		);
	}
}
