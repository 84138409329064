import React from 'react';
import MainContainerV2 from './UI/MainContainerV2'

function App() {
  return (
    <MainContainerV2/>
  );
}

export default App;
