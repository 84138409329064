import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";

// data
import { actionMenu } from "../data/mockData";

//img
import pattern_footer from '../images/pattern_footer.png'

// action
import { getConnectTokenAction } from "../action/getConnectTokenAction";
import { getServiceTicketAction } from "../action/getServiceTicketAction";
import { getMissTicketAction } from "../action/getMissTicketAction";
import { getAdvertiseImgAction } from "../action/getAdvertiseImgAction";
import { getCompleteTicketAction } from "../action/getCompleteTicketAction";
import { currentTicketAction } from "../action/currentTicketAction";

//component
import HeaderContainer from "./Header/HeaderContainer";
import PopupContainer from "./Popup/PopupContainer";
import AdvertisementImagePage from "./MainScreen/AdvertisementImagePage";
import TicketBoardPageT from "./MainScreen/TicketBoardPageT";
import ControlContainerV2 from "./Control/ControlContainerV2";
import WaitingPage from "./MainScreen/WaitingPage";
import { version } from '../../package.json'

// helper
import { Nzero } from "../tools";

// host
import { linkToken } from "../host";

// audio
import { endVN, listVN, startVN } from "../data/dataAudio";

// constant
import { timeWaitbyTwoAudio } from "../constConfig";

const Bound = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	/* position: relative; */
	min-width: 768px;
	/* overflow: hidden; */
    background: #7ED3F7;
    .pattert{
        height: 48px;
        width: 100%;
        flex-shrink: 0;
    }
    .version{
        font-size: 12px;
        font-weight: 500;
        line-height: 1.42;
        color: #005993;
        position: absolute;
        bottom: 54px;
        left: 40px;
    }

`;

class MainContainerV2 extends Component {
    constructor(props) {
        super(props);
        this.controlContainer = null;
        this.arrAudioWait = [];
        this.arrAudioWaitAll = [];
        this.arrAudioRunning = [];
        this.arrTicketWait = [];
        this.indexAu = 0;
        this.reading = false;
        this.icallToken = 0;
        this.popupRef = {
            type: -1,
            data: null,
        };
        this.mSocketStatus = 0;

        this.state = {
            branch: null,
            page: 1,
            isShowPopup: false,
        };
    }

    dataCounter = async (data) => {
        await this.props.getConnectTokenAction(data.LcdID);
        await this.props.getAdvertiseImgAction(data.OfficeID);
        await this.props.currentTicketAction(data.OfficeID);
        const { Token } = await this.props.getConnectToken;
        await this.testWebSocket(Token);
        const { MissCall, CurrentInCounter } = await this.props.currentTicket;
        if (MissCall.length > 0 || CurrentInCounter.length > 0) {
            this.setState({
                branch: data,
                page: 3,
            });
        } else {
            const { AdvertiseList } = await this.props.getAdvertiseImg;
            if (AdvertiseList.length > 0) {
                this.setState({
                    branch: data,
                    page: 2,
                });
            } else {
                this.setState({
                    branch: data,
                    page: 1,
                });
            }
        }
    };
    recallSocket = () => {
        const { isShowPopup } = this.state;
        if (isShowPopup) {
            this.setState({ isShowPopup: false }, () => {
                this.popupRef.type = 4;
                this.setState({
                    isShowPopup: true,
                });
            });
        } else {
            this.popupRef.type = 4;
            this.setState({
                isShowPopup: true,
            });
        }
    };
    needRefreshPage = () => {
        window.location.reload()
        // const { isShowPopup } = this.state;
        // if (isShowPopup) {
        // 	this.setState({ isShowPopup: false }, () => {
        // 		this.popupRef.type = 5;
        // 		this.setState({
        // 			isShowPopup: true,
        // 		});
        // 	});
        // } else {
        // 	this.popupRef.type = 5;
        // 	this.setState({
        // 		isShowPopup: true,
        // 	});
        // }
    };
    getDisconnectInter = () => {
        const { isShowPopup } = this.state;
        if (isShowPopup) {
            this.setState({ isShowPopup: false }, () => {
                this.popupRef.type = 2;
                this.setState({
                    isShowPopup: true,
                    disconnectInter: true,
                });
            });
        } else {
            this.popupRef.type = 2;
            this.setState({
                isShowPopup: true,
                disconnectInter: true,
            });
        }
    };
    onClosePopup = () => {
        const myAuder = document.getElementById("bound-audio");
        myAuder.muted = true;
        myAuder.currentTime = 0;
        myAuder.play();
        this.setState({
            isShowPopup: false,
        });
    };
    disconnectBranch = (value) => {
        localStorage.removeItem("dataBranch");
        this.setState({
            branch: null,
            page: 1,
        });
        if (value) {
            return;
        } else {
            this.ws.close();
        }
    };
    logout403 = () => {
        localStorage.clear();
        this.setState(
            {
                branch: null,
                page: 1,
            },
            () => {
                this.popupRef.type = 6;
                this.setState({
                    isShowPopup: true,
                });
            }
        );
    };
    onChangeAction = (key) => {
        switch (key) {
            case actionMenu.settingBranch.key:
                this.showPopup();
                break;
            case actionMenu.advertisement.key:
                this.setState({
                    isShowPopup: false,
                    page: 2,
                });
                break;
            case actionMenu.ticketBoard.key:
                this.setState({
                    isShowPopup: false,
                    page: 3,
                });
                break;
            default:
                break;
        }
    };

    onChangePage = async (page) => {
        if (this.state.disconnectInter) {
            this.popupRef.type = 3;
            this.setState({
                isShowPopup: true,
                page,
                disconnectInter: false,
            });
        } else {
            this.setState({ page, isShowPopup: false, disconnectInter: false });
        }
        //--------------
        if (page === 2 || page === 3) {
            const { Token: TokenCheck } = this.props.getConnectToken;
            if (TokenCheck === null || TokenCheck === undefined) {
                const { LcdID } = this.props.connectLcdReducer
                await this.props.getConnectTokenAction(LcdID);
                const { Token } = await this.props.getConnectToken;
                this.testWebSocket(Token);
            }
        }
    };
    componentWillReceiveProps(newProps) {
        if (this.props.getServiceTicket !== newProps.getServiceTicket) {
            let serTicket = newProps.getServiceTicket;
            // console.log("serTicket", serTicket);
            let arrTicketAu = [],
                arrCounterAu = [],
                playlist = [];
            let arrTicketNumber = Nzero(serTicket.TicketNumber)
                .toString()
                .split("");
            let arrCounterNumber = Nzero(serTicket.CounterNumber)
                .toString()
                .split("");
            arrTicketNumber.forEach((item) => {
                listVN.forEach((type) => {
                    if (type.type === parseInt(item)) {
                        arrTicketAu.push({ url: type.url });
                    }
                });
            });
            arrCounterNumber.forEach((item) => {
                listVN.forEach((type) => {
                    if (type.type === parseInt(item)) {
                        arrCounterAu.push({ url: type.url });
                    }
                });
            });
            playlist = playlist.concat(
                startVN,
                arrTicketAu,
                endVN,
                arrCounterAu
            );
            this.arrAudioWait.push(playlist);
            this.arrTicketWait.push(serTicket);
            if (this.arrAudioRunning.length === 0 && !this.reading) {
                let page = this.state.page;
                if (page !== 3) {
                    let dataBranch = localStorage.getItem("dataBranch");
                    if (dataBranch) {
                        this.props.currentTicketAction(
                            JSON.parse(dataBranch).OfficeID
                        );
                    }
                    this.setState(
                        {
                            page: 3,
                        },
                        () => {
                            setTimeout(() => {
                                this.setState({
                                    serTicket,
                                });
                            }, 1000);
                        }
                    );
                } else {
                    setTimeout(() => {
                        this.setState({
                            serTicket,
                        });
                    }, 1000);
                }
                // ------------------------
                this.arrAudioRunning = playlist;
                setTimeout(() => {
                    this.togglePlay();
                }, 1000);
            }
        }
        if (this.props.logout403Reducer !== newProps.logout403Reducer) {
            // console.log("logout403Reducer", newProps.logout403Reducer);
            const { status } = newProps.logout403Reducer;
            if (status !== null) {
                this.logout403();
            }
        }
    }
    testWebSocket = (Token) => {
        let wsUri = linkToken(Token);
        this.ws = new WebSocket(wsUri);
        this.ws.onopen = (evt) => {
            // console.log("open");
            if (evt) {
                this.setState({
                    disconnectInter: false,
                });
            }
        };
        this.ws.onmessage = (evt) => {
            const data = JSON.parse(evt.data);
            // console.log("socket", data);
            const { OfficeID } = this.props.connectLcdReducer
            switch (data.Event) {
                case "LCD_GET_TICKET":{
                    this.props.getServiceTicketAction(data.Data);
                    this.props.currentTicketAction(OfficeID)
					if(this.state.page === 2){
						this.onChangePage(3)
					}
				}
                    break;
                case "LCD_MISS_TICKET":
                    this.props.getMissTicketAction(data.Data);
                    this.props.currentTicketAction(OfficeID)
                    break;
                case "LCD_COMPLETE_TICKET":
                    this.props.getCompleteTicketAction(data.Data);
                    //this.props.currentTicketAction(OfficeID)
                    break;
                case "LCD_RECALL_TICKET":{
                    this.props.getServiceTicketAction(data.Data);
                    this.props.currentTicketAction(OfficeID)
					if(this.state.page === 2){
						this.onChangePage(3)
					}
				}
                    break;
                case "LCD_MOVING_TICKET":
                    this.props.currentTicketAction(OfficeID)
                case "LCD_NEW_TICKET":
                    this.props.currentTicketAction(OfficeID)
                default:
                    break;
            }
        };
        this.ws.onerror = (evt) => {
            // console.log("error");
        };
        this.ws.onclose = async (evt) => {
            // console.log("close");
            if (this.state.branch !== null) {
                this.recallSocket();
                const { LcdID } = this.props.connectLcdReducer
                await this.props.getConnectTokenAction(LcdID);
                const { Token } = await this.props.getConnectToken;
                // console.log("reCall--Token", Token);
                if (Token) {
                    this.testWebSocket(Token);
                } else {
                    this.icallToken++;
                    setTimeout(() => {
                        const { Token } = this.props.getConnectToken;
                        // console.log("MISS-Token", Token);
                        // console.log("icallToken", this.icallToken);
                        if (Token && this.icallToken < 4) {
                            this.testWebSocket(Token);
                        } else {
                            this.needRefreshPage();
                        }
                    }, 10000);
                }
            }
        };
    };
    // -------------------
    togglePlay = () => {
        this.reading = true;
        const myAuder = document.getElementById("bound-audio");
        myAuder.muted = false;
        myAuder.src = this.arrAudioRunning[0].url;
        myAuder.currentTime = 0;
        const myPromise = myAuder.play();
        if (myPromise !== undefined) {
            myPromise.catch(() => {
                myAuder.play();
            });
        }
    };
    onEndAudioRef = (event) => {
        event.preventDefault();
        let n = this.arrAudioRunning.length;
        const myAuder = document.getElementById("bound-audio");
        this.indexAu++;
        if (this.indexAu < n) {
            myAuder.src = this.arrAudioRunning[this.indexAu].url;
            myAuder.pause();
            myAuder.currentTime = 0;
            const myPromise = myAuder.play();
            if (myPromise !== undefined) {
                myPromise.catch(() => {
                    myAuder.play();
                });
            }
        } else {
            this.arrAudioWait.shift();
            this.arrTicketWait.shift();
            this.indexAu = 0;
            this.reading = false;
            if (this.arrAudioWait.length > 0 && this.arrTicketWait.length > 0) {
                this.arrAudioRunning = this.arrAudioWait[0];
                let serTicket = this.arrTicketWait[0];
                setTimeout(() => {
                    this.togglePlay();
                    this.setState({ serTicket });
                }, timeWaitbyTwoAudio);
            } else {
                this.arrAudioRunning = [];
                // if (this.ticketBoardPage) {
                //     this.ticketBoardPage.resetTranferPage();
                // }
            }
        }
    };

    //----------------------------
    render() {
        const { serTicket, isShowPopup, disconnectInter } = this.state;
        let content = undefined;

        switch (this.state.page) {
            case 1:
                content = (
                    <WaitingPage
                        getDisconnectInter={this.getDisconnectInter}
                        onChangePage={this.onChangePage}
                    />
                );
                break;
            case 2:
                content = (
                    <AdvertisementImagePage
                        getDisconnectInter={this.getDisconnectInter}
                        onChangePage={this.onChangePage}
                    />
                );
                break;
            case 3:
                content = (
                    <TicketBoardPageT
                        getDisconnectInter={this.getDisconnectInter}
                        serTicket={serTicket}
                        onChangePage={this.onChangePage}
                        ref={(div) => (this.ticketBoardPage = div)}
                    />
                );
                break;
            default:
                break;
        }
        return (
            <Bound show={this.state.isShowMenu} active={this.state.isActive}>
                <HeaderContainer branch={this.state.branch} />
                <audio id="bound-audio" onEnded={this.onEndAudioRef}>
                    <source id="audio_wav" src={""} type="audio/wav" />
                </audio>
                {content}
                <div className='version'>Phiên bản {version} </div>

                <ControlContainerV2
                    disconnectInter={disconnectInter}
                    disconnectBranch={this.disconnectBranch}
                    dataCounter={this.dataCounter}
                    branch={this.state.branch}
                    isConnected={this.state.branch ? true : false}
                    onChangeAction={this.onChangeAction}
                />
                {isShowPopup && (
                    <PopupContainer
                        popupRef={this.popupRef}
                        onClosePopup={this.onClosePopup}
                    />
                )}
                <img className="pattert" src={pattern_footer} alt="footer" />
            </Bound>
        );
    }
}

const mapDispathToProps = (dispatch) => {
    return {
        getConnectTokenAction: (id) => dispatch(getConnectTokenAction(id)),
        getServiceTicketAction: (data) => dispatch(getServiceTicketAction(data)),
        getMissTicketAction: (data) => dispatch(getMissTicketAction(data)),
        getCompleteTicketAction: (data) => dispatch(getCompleteTicketAction(data)),
        getAdvertiseImgAction: (offID) => dispatch(getAdvertiseImgAction(offID)),
        currentTicketAction: (offID) => dispatch(currentTicketAction(offID)),
    };
};
const mapStateToProps = (state) => {
    return {
        connectLcdReducer: state.connectLcdReducer,
        getConnectToken: state.getConnectTokenReducer,
        getServiceTicket: state.getServiceTicketReducer,
        currentTicket: state.currentTicketReducer,
        getAdvertiseImg: state.getAdvertiseImgReducer,
        logout403Reducer: state.logout403Reducer,
    };
};

export default connect(mapStateToProps, mapDispathToProps)(MainContainerV2);
