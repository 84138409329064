import React, { Component } from "react";
import styled from "styled-components";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// link func
import { host } from "../../host";
import { connect } from "react-redux";
import { setTimeSpeed, setTimeAutoPlaySpeed } from "../../constConfig";
// action
import { currentTicketAction } from "../../action/currentTicketAction";
import { getAdvertiseImgAction } from "../../action/getAdvertiseImgAction";

const Bound = styled.div`
	flex-grow: 1;

	&::before, &::after {
		content: '';
		clear: both;
	}

	.wrap-img {
		outline: none;
		img {
			width: 100vw;
			height: calc(90vh - 12px);
			object-fit: contain;
		}
	}

`;

class AdvertisementImagePage extends Component {
	constructor() {
		super();
		this.state = {
			images: [],
		};
	}
	componentDidMount() {
		const dataIMG = this.props.getAdvertiseImg;
		if (
			dataIMG &&
			dataIMG.AdvertiseList &&
			dataIMG.AdvertiseList.length > 0
		) {
			let images = [];
			dataIMG.AdvertiseList.forEach((element) => {
				images.push(host + "lcd/ads/file/" + element);
			});
			this.setState({
				images,
			});
		}
	}

	beforeChange = async (e) => {
		const n = this.state.images.length;
		if (e === n - 1) {
			let data = JSON.parse(localStorage.getItem("dataBranch"));
			await this.props.currentTicketAction(data.OfficeID);
			let { MissCall, CurrentInCounter } = await this.props.currentTicket;
			if (MissCall.length > 0 || CurrentInCounter.length > 0) {
				this.props.onChangePage(3);
			} else {
				this.props.getAdvertiseImgAction(data.OfficeID);
			}
		}
	};
	componentWillReceiveProps(newProps) {
		if (this.props.disconnectReducer !== newProps.disconnectReducer) {
			this.props.getDisconnectInter(newProps.disconnectReducer.err);
			this.props.onChangePage(1);
		}
		if (this.props.getAdvertiseImg !== newProps.getAdvertiseImg) {
			const { AdvertiseList } = newProps.getAdvertiseImg;
			if (AdvertiseList.length > 0) {
				let images = [];
				AdvertiseList.forEach((element) => {
					images.push(host + "lcd/ads/file/" + element);
				});
				this.setState({
					images,
				});
			} else {
				this.props.onChangePage(1);
			}
		}
	}
	render() {
		let { images } = this.state;
		const settings = {
			infinite: true,
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplay: true,
			speed: setTimeSpeed,
			autoplaySpeed: setTimeAutoPlaySpeed,
			pauseOnHover: false,
			arrows: false
		};
		return (
			<Bound>
				<Slider
					{...settings}
					beforeChange={this.beforeChange}
				>
					{images.map((image, index) => {
						return (
							<div key={index} className="wrap-img">
								<img src={image} alt="advertise" />
							</div>
						);
					})}
				</Slider>
			</Bound>
		);
	}
}

const mapDispathToProps = (dispatch) => {
	return {
		getAdvertiseImgAction: (offID) =>
			dispatch(getAdvertiseImgAction(offID)),
		currentTicketAction: (offID) => dispatch(currentTicketAction(offID)),
	};
};
const mapStateToProps = (state) => ({
	getAdvertiseImg: state.getAdvertiseImgReducer,
	currentTicket: state.currentTicketReducer,
	disconnectReducer: state.disconnectReducer,
});
export default connect(
	mapStateToProps,
	mapDispathToProps
)(AdvertisementImagePage);
