import React from 'react'
import ReactDOM from 'react-dom'

const Modal = ({ isShow, children }) => isShow?
    ReactDOM.createPortal(
        <div id="modal-wrapper">
            {children}
        </div>,
        document.querySelector('body'),
    )
    :
    null
export default Modal
