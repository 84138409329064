import React, { Component } from "react";
import styled from "styled-components";
import TickyRowV2 from "../Control/TickyRowV2";
import TickyNow from "./../Control/TickyNow";
import { timeChangePage } from "../../constConfig";
// connect
import { connect } from "react-redux";
import { currentTicketAction } from "../../action/currentTicketAction";
import { getAdvertiseImgAction } from "../../action/getAdvertiseImgAction";
import TicketRowV3 from "../Control/TicketRowV3";

const marginTickyNow = 200;
const Bound = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	overflow: hidden;
	position: relative;
    padding: 32px 40px;
    box-sizing: border-box;
    width: 100%;
    height: calc(100% - 152px - 48px);
    height: 100%;
    min-width: 1920px;
	.head-table {
        width: 100%;
        height: 88px;
        display: grid;
        grid-template-columns: 320px minmax(660px, 1fr) 420px 440px;
        border-top: 1px solid #005993;
        border-left: 1px solid #005993;
        border-right: 1px solid #005993;		
        .header{
            height: 88px;
            font-family: 'SVN-Gilroy';
            font-size: 32px;
            font-weight: 700;
            line-height: 48px;
            letter-spacing: 1px;
            text-align: center;
            padding: 24px 16px 20px 16px;
            box-sizing: border-box;
            color: #005993;
            background: #C6E9FC;
            border-right: 1px solid #005993;
            text-transform: uppercase;
        }
        @media screen and (min-width: 1920px) {			
			.header {
				font-size: 38px;
			}
		}
	}
	.ticky-now {
		display: flex;
		background: #fff;
		align-items: center;
		transition: all 1s;
		position: absolute;
		width: 100%;
		bottom: 0;
		height: 12vh;
		.text {
			width: 380px;
			display: flex;
			align-items: center;
			justify-content: center;
			font-family: SVN-Gilroy;
			font-size: 36px;
			font-weight: bold;
			line-height: 1.2;
			color: #222222;
		}
		.number-ticky {
			display: flex;
			flex: 1;
			overflow: hidden;
			height: 100%;
			align-items: center;
			justify-content: flex-end;
		}
		.block-move {
			display: flex;
			align-items: center;
			justify-content: center;
			position: relative;
			right: -${(props) => props.elmMove}px;
		}

		.animation {
			animation: key_mov ${(props) => props.count}s infinite;
			animation-timing-function: linear;
			animation-iteration-count: 1;
			animation-delay: 0.5s;
		}
		@keyframes key_mov {
			from {
				right: -${(props) => props.elmMove}px;
			}
			to {
				right: ${(props) => props.elmCheck}px;
			}
		}
	}
	.hiddenDiv {
		height: 0;
		flex: unset !important;
		overflow: hidden;
	}
	.bound-current-ticket {
        width: 100%;
		flex: 1;
        border: 1px solid #005993;
        display: grid;
        grid-template-rows: repeat(9, 1fr);
	}
`;

class TicketBoardPageT extends Component {
    constructor() {
        super();
        this.storeCurrentInCounter = [];
        this.storeRunningArray = [];
        this.curentPageView = 1;
        this.totalPage = 0;
        this.state = {
            arrayService: this.makeNewArray(9),
            runingArray: [],
            elmMove: 0,
            elmCheck: 0,
            count: 10,
            curentPage: 1,
        };
    }
    componentDidMount() {
        //this.element.addEventListener("webkitAnimationEnd", this.animationEnd);
        this.tranferToAdvertPage();
        const { MissCall, CurrentInCounter } = this.props.currentTicket;
        if (MissCall.length > 0 || CurrentInCounter.length > 0) {
            this.renderCurrentTicketInCounter(MissCall, CurrentInCounter);
        }
    }
    renderCurrentTicketInCounter(MissCall, CurrentInCounter) {
        let { arrayService } = this.state;
        let runingArray = [];
        // arrayService ----------
        this.storeCurrentInCounter = CurrentInCounter;
        let non = 9 - this.storeCurrentInCounter.length;
        if (non > 0) {
            let arr = this.makeNewArray(non);
            arrayService = this.storeCurrentInCounter.concat(arr);
        } else {
            if (this.curentPageView === 1) {
                arrayService = this.storeCurrentInCounter.slice(0, 9)
            } else if (this.curentPageView === 2) {
                let newArrayService2 = this.storeCurrentInCounter.slice(9, 18);
                let non = 9 - newArrayService2.length;
                if (non > 0) {
                    let arr = this.makeNewArray(non);
                    arrayService = newArrayService2.concat(arr);
                } else {
                    arrayService = this.storeCurrentInCounter.slice(9, 18)
                }
            } else {
                let newArrayService3 = this.storeCurrentInCounter.slice(18, 27);
                let non = 9 - newArrayService3.length;
                if (non > 0) {
                    let arr = this.makeNewArray(non);
                    arrayService = newArrayService3.concat(arr);
                } else {
                    arrayService = this.storeCurrentInCounter.slice(18, 27)
                }
            }
        }
        // MISS CALL ----------
        runingArray = MissCall ? MissCall : [];
        this.storeRunningArray = MissCall ? MissCall : [];
        if (arrayService.length > 0) {
            this.setState(
                {
                    arrayService,
                    runingArray,
                    curentPage: this.state.curentPage,
                },
                () => {
                    this.hiddenMissCall();
                    const N = this.state.runingArray.length;
                    if (N > 0) {
                        this.renderStyleArrRunning(N);
                    }
                }
            );
        } else {
            this.setState(
                {
                    arrayService: this.makeNewArray(9),
                    runingArray,
                    curentPage: this.state.curentPage,
                },
                () => {
                    this.hiddenMissCall();
                    const N = this.state.runingArray.length;
                    if (N > 0) {
                        this.renderStyleArrRunning(N);
                    }
                }
            );
        }
    }

    makeNewArray = (n) => {
        let Arr = [];
        for (let i = 0; i < n; i++) {
            Arr.push({});
        }
        return Arr;
    };
    renderArrTicket(data) {
        if (this.storeCurrentInCounter.length > 0) {
            this.storeCurrentInCounter.forEach((item, i) => {
                if (item.TicketNumber === data.TicketNumber) {
                    this.storeCurrentInCounter.splice(i, 1);
                }
            });
        }
        if (this.storeRunningArray.length > 0) {
            this.storeRunningArray.forEach((item, i) => {
                if (item.TicketNumber === data.TicketNumber) {
                    this.storeRunningArray.splice(i, 1);
                }
            });
        }
        // ==============================
        let currenTicket = {
            ...data,
            time: new Date().getTime(),
        };
        let arrPrepare;

        let non = 8 - this.storeCurrentInCounter.length;

        if (non > 0) {
            let arr = this.makeNewArray(non);
            arrPrepare = this.storeCurrentInCounter.concat(arr);
        } else {
            arrPrepare = this.storeCurrentInCounter.slice(0, 8);
        }
        arrPrepare.unshift(currenTicket);
        let arrayService = arrPrepare;
        this.storeCurrentInCounter.unshift(data);
		// console.log("renderArrTicket")
        clearTimeout(this.timer);
        this.setState(
            {
                arrayService,
                curentPage: 1,
            },
            () => {
                this.hiddenMissCall();
            }
        );
    }
    completeTicket = (data) => {
        if (this.storeCurrentInCounter.length > 0) {
            this.storeCurrentInCounter.forEach((item, i) => {
                if (item.TicketNumber === data.TicketNumber) {
                    this.storeCurrentInCounter.splice(i, 1);
                }
            });
        }
        let non = 9 - this.storeCurrentInCounter.length;
        let arrayService;
        if (non > 0) {
            let arr = this.makeNewArray(non);
            arrayService = this.storeCurrentInCounter.concat(arr);
        } else {
			let isPrevious = false
            if (this.curentPageView === 1) {
                arrayService = this.storeCurrentInCounter.slice(0, 9)
            } else if (this.curentPageView === 2) {
                let newArrayService2 = this.storeCurrentInCounter.slice(9, 18);
                let non = 9 - newArrayService2.length;
                if (non > 0 && non < 9) {
                    let arr = this.makeNewArray(non);
                    arrayService = newArrayService2.concat(arr);
                } else if (non === 0) {
                    arrayService = newArrayService2
                }
				else {
					arrayService = this.storeCurrentInCounter.slice(0, 9)
					isPrevious = true
				}
            } else {
                let newArrayService3 = this.storeCurrentInCounter.slice(18, 27);
                let non = 9 - newArrayService3.length;
                if (non > 0 && non < 9) {
                    let arr = this.makeNewArray(non);
                    arrayService = newArrayService3.concat(arr);
                } else if (non === 0) {
                    arrayService = newArrayService3
                }
				else {
					arrayService = this.storeCurrentInCounter.slice(0, 9)
					isPrevious = true
				}
            }
			if(isPrevious){
				this.curentPageView = 1
			}
        }
        this.setState({ arrayService, curentPage: this.curentPageView }, () => {
            // clearTimeout(this.timer);
            // this.tranferToAdvertPage();
        });
    };

    renderArrMissTicket(data) {
        let runingArray = [];
        if (this.state.runingArray.length === 0) {
            runingArray.push(data);
            this.setState({ runingArray }, () => {
                const N = this.state.runingArray.length;
                this.hiddenMissCall();
                if (N > 0) {
                    this.renderStyleArrRunning(N);
                }
            });
        }
        this.storeRunningArray.unshift(data);
        if (this.storeCurrentInCounter.length > 0) {
            this.storeCurrentInCounter.forEach((item, i) => {
                if (item.TicketNumber === data.TicketNumber) {
                    this.storeCurrentInCounter.splice(i, 1);
                }
            });
        }
        let arrayService;
        let non = 9 - this.storeCurrentInCounter.length;
        if (non > 0) {
            let arr = this.makeNewArray(non);
            arrayService = this.storeCurrentInCounter.concat(arr);
        } else {
            arrayService = this.storeCurrentInCounter.slice(0, 9);
        }

        this.setState({ arrayService }, () => {
			// console.log("renderArrMissTicket")
            clearTimeout(this.timer);
            this.tranferToAdvertPage();
        });
    }
    hiddenMissCall = () => {
        //if (this.storeRunningArray.length === 0) {
        //    this.blockMissCallRef.classList.add("hiddenDiv");
        //} else {
        //    this.blockMissCallRef.classList.remove("hiddenDiv");
        //}
    };

    componentWillReceiveProps(newProps) {
        if (this.props.currentTicket !== newProps.currentTicket) {
            const { MissCall, CurrentInCounter } = newProps.currentTicket;
            this.renderCurrentTicketInCounter(MissCall, CurrentInCounter);
        }
        //if (this.props.getMissTicket !== newProps.getMissTicket) {
        //    this.renderArrMissTicket(newProps.getMissTicket);
        //}
        //if (this.props.serTicket !== newProps.serTicket) {
        //    this.renderArrTicket(newProps.serTicket);
        //}
        if (this.props.getCompleteTicket !== newProps.getCompleteTicket) {
            this.completeTicket(newProps.getCompleteTicket);
        }
        if (this.props.disconnectReducer !== newProps.disconnectReducer) {
            this.props.getDisconnectInter(newProps.disconnectReducer.err);
            this.props.onChangePage(1);
        }
    }
    resetTranferPage() {
		// console.log("reset TranferPage")
        clearTimeout(this.timer);
        this.tranferToAdvertPage();
    }

    tranferToAdvertPage() {
        this.timer = setTimeout(async () => {
			// console.log("start timer")
            let { curentPage } = this.state;
            let arrayService;
            let bin = this.storeRunningArray.length;

            if (bin > 0) {
                this.totalPage = Math.ceil(
                    this.storeCurrentInCounter.length / 8
                );
            } else {
                this.totalPage = Math.ceil(
                    this.storeCurrentInCounter.length / 9
                );
            }
            curentPage++;
            if (curentPage <= this.totalPage && curentPage <= 3) {
                if (bin > 0) {
                    arrayService = this.storeCurrentInCounter.slice(
                        8 * curentPage - 8,
                        8 * curentPage
                    );
                    arrayService.push({});
                    let non = 9 - arrayService.length;
                    if (non > 0) {
                        let arr = this.makeNewArray(non);
                        arrayService = arrayService.concat(arr);
                    }
                } else {
                    arrayService = this.storeCurrentInCounter.slice(
                        9 * curentPage - 9,
                        9 * curentPage
                    );
                    let non = 9 - arrayService.length;
                    if (non > 0) {
                        let arr = this.makeNewArray(non);
                        arrayService = arrayService.concat(arr);
                    }
                }
                this.curentPageView = curentPage
                this.setState({ arrayService, curentPage }, () => {
                    this.resetTranferPage();
                });
				// console.log("case A")
            } else {
                let dataBranch = localStorage.getItem("dataBranch");
                if (dataBranch) {
                    await this.props.getAdvertiseImgAction(
                        JSON.parse(dataBranch).OfficeID
                    );
                    const { AdvertiseList } = await this.props.getAdvertiseImg;
                    if (AdvertiseList.length > 0) {
                        this.props.onChangePage(2);
                    } else {
                        this.props.currentTicketAction(
                            JSON.parse(dataBranch).OfficeID
                        );
                        this.resetTranferPage();
                    }
                }
				// console.log("case B")
            }
        }, timeChangePage);
    }

    renderStyleArrRunning(N) {
        //let boundMove = document.getElementById("block-move-id");
        //if (boundMove) {
        //    boundMove.classList.remove("animation");
        //}
        //let count = 5 + 2 * N;
        //this.setState(
        //    {
        //        count,
        //        elmMove: this.element.offsetWidth,
        //        elmCheck: this.elementCheck.offsetWidth,
        //    },
        //    () => {
        //        boundMove.classList.add("animation");
        //    }
        //);
    }

    animationEnd = () => {
        //let boundMove = document.getElementById("block-move-id");
        //if (boundMove) {
        //    boundMove.classList.remove("animation");
        //}
        //let N = this.storeRunningArray.length;
        //let count = 5 + 2 * N;
        //this.setState(
        //    {
        //        runingArray: this.storeRunningArray,
        //        count,
        //        elmMove: this.element.offsetWidth,
        //        elmCheck: this.elementCheck.offsetWidth,
        //    },
        //    () => {
        //        boundMove.classList.add("animation");
        //    }
        //);
    };
    render() {
        let {
            arrayService,
            elmMove,
            elmCheck,
            runingArray,
            count,
        } = this.state;

        return (
            <Bound count={count} elmMove={elmMove} elmCheck={elmCheck}>
                <div className="head-table">
                    <div className='header'>Số vé</div>
                    <div className='header'>Tên Khách hàng</div>
                    <div className='header'>Nhóm Khách hàng</div>
                    <div className='header'>Trạng thái GD</div>
                </div>
                <div className="bound-current-ticket">
                    {arrayService.map((item, index) => {
                        return (
                            <TicketRowV3 data={item} key={index} index={index} />
                        );
                    })}
                </div>
                {/*<div
                    className="ticky-now"
                    ref={(div) => (this.blockMissCallRef = div)}>
                    <div className="text">Số vé gọi nhỡ:</div>
                    <div
                        className="number-ticky"
                        ref={(elm) => (this.elementCheck = elm)} >
                        <div
                            className="block-move"
                            id="block-move-id"
                            ref={(elm) => (this.element = elm)}>
                            {runingArray &&
                                runingArray.length > 0 &&
                                runingArray.map((item, index) => {
                                    return (
                                        <TickyNow
                                            item={item}
                                            key={index}
                                            marginRight={marginTickyNow}
                                        />
                                    );
                                })}
                        </div>
                    </div>
                </div>*/}
            </Bound>
        );
    }
    componentWillUnmount() {
		// console.log("componentWillUnmount")
        clearTimeout(this.timer);
        //this.element.removeEventListener(
        //    "webkitAnimationEnd",
        //    this.animationEnd
        //);
    }
}
const mapDispathToProps = (dispatch) => {
    return {
        getAdvertiseImgAction: (offID) => dispatch(getAdvertiseImgAction(offID)),
        currentTicketAction: (offID) => dispatch(currentTicketAction(offID)),
    };
};
const mapStateToProps = (state) => ({
    currentTicket: state.currentTicketReducer,
    getCompleteTicket: state.getCompleteTicketReducer,
    getMissTicket: state.getMissTicketReducer,
    getAdvertiseImg: state.getAdvertiseImgReducer,
    disconnectReducer: state.disconnectReducer,
});
export default connect(mapStateToProps, mapDispathToProps, null, {
    forwardRef: true,
})(TicketBoardPageT);