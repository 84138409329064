const hostConfigs = {
    dev: 'http://localhost:9090/api/',
    ppe: 'https://prekiosklcds.vietinbank.vn/api/',
    product: 'https://sdblcd.vietinbank.vn/api/',
    wee: 'https://vtb1-lcd.wee.vn/api/'
}

const webSocketUrlConfigs = {
    dev: token => `ws://localhost:8999`,
    // dev: token => `wss://prekiosklcds.vietinbank.vn/ws/connection/websocket?token=${token}`,
    ppe: token => `wss://prekiosklcds.vietinbank.vn/ws/connection/websocket?token=${token}`,
    product: token => `wss://sdblcd.vietinbank.vn/ws/connection/websocket?token=${token}`,
    wee: token => `wss://vtb1-lcd.wee.vn/ws/connection/websocket?token=${token}`
}

const host = process.env.REACT_APP_BUILD_TYPE
    ? hostConfigs[process.env.REACT_APP_BUILD_TYPE]
    : hostConfigs.dev
const linkToken = process.env.REACT_APP_BUILD_TYPE
    ? webSocketUrlConfigs[process.env.REACT_APP_BUILD_TYPE]
    : webSocketUrlConfigs.dev

export { host, linkToken }

console.log("build WEE: 11/11/2024 14:40")

