import ic_setting_gray from "../images/ic_setting_gray.svg";
import ic_setting_blue from "../images/ic_setting_blue.svg";
import ic_ticket_board_gray from "../images/ic_ticket_board_gray.svg";
import ic_ticket_board_blue from "../images/ic_ticket_board_blue.svg";
import ic_advertising_gray from "../images/ic_advertising_gray.svg";
import ic_advertising_blue from "../images/ic_advertising_blue.svg";

export const branchCounter = [
	{
		id: "VT1458",
		pinCode: "12345",
		name: "CN Tân Bình",
		address: "79A Hàm Nghi, P.Nguyễn Thái Bình, Q.Tân Bình, TP.HCM",
	},
	{
		id: "VT7815",
		pinCode: "12345",
		name: "CN Hoàng Cầu",
		address: "79A Hàm Nghi, P.Nguyễn Thái Bình, Q.Tân Bình, TP.HCM",
	},
	{
		id: "VT1459",
		pinCode: "12345",
		name: "CN Tân Bình",
		address: "79A Hàm Nghi, P.Nguyễn Thái Bình, Q.Tân Bình, TP.HCM",
	},
	{
		id: "VT7817",
		pinCode: "12345",
		name: "CN Hoàng Cầu",
		address: "79A Hàm Nghi, P.Nguyễn Thái Bình, Q.Tân Bình, TP.HCM",
	},
];

export const popupType = {
	configCouterLogin: 0,
	configCouterSuccess: 1,
};

export const actionMenu = {
	settingBranch: {
		name: "Seting Branch",
		activeIcon: ic_setting_blue,
		disActiveIcon: ic_setting_gray,
		key: "settingBranch",
	},
	advertisement: {
		name: "Advertisement",
		activeIcon: ic_advertising_blue,
		disActiveIcon: ic_advertising_gray,
		key: "advertisement",
	},
	ticketBoard: {
		name: "Ticket Board",
		activeIcon: ic_ticket_board_blue,
		disActiveIcon: ic_ticket_board_gray,
		key: "ticketBoard",
	},
};

export const dataTicket = [
	{ ticky: 201, counter: 1 },
	{ ticky: 202, counter: 12 },
	{ ticky: 203, counter: 9 },
	{ ticky: 204, counter: 7 },
];

export const counters = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
export const tickits = [
	101,
	102,
	103,
	104,
	105,
	106,
	107,
	108,
	109,
	110,
	201,
	202,
	203,
	502,
	392,
	123,
	233,
	445,
	231,
];
