import React, { Component } from "react";
import styled from "styled-components";

//component
import ActionPopupContainer from "./ActionPopupContainer";
//data
import { actionMenu } from "../../data/mockData";

const Bound = styled.div`
	clear: both;
	position: fixed;
	top: 20%;
	right: 0;
	display: inline-block;
	border-radius: 2.5px;
	width: 6px;
	height: 240px;
	background: rgba(0, 89, 147, 0.5);
`;

export default class ControlContainerV2 extends Component {
	closeMenuTimeout = null;
	isMouseEnter = false;
	isActive = false;
	indexActive = "";
	state = {
		isShowMenu: false,
	};

	reset() {
		this.isActive = false;
		this.indexActive = "";
		if (this.actionMenuContainer) {
			this.actionMenuContainer.reset();
		}
		if (!this.isMouseEnter) {
			this.onMouseLeaveControlBar();
		}
	}
	onMouseEnterControlBar = (event) => {
		this.isMouseEnter = true;
		clearTimeout(this.closeMenuTimeout);
		let { isShowMenu } = this.state;
		if (!isShowMenu) {
			this.setState(
				{
					isShowMenu: true,
				},
				() => {
					this.showMenu();
					if (this.props.branch !== null) {
						setTimeout(() => {
							this.isMouseEnter = false;
							if (!this.isActive) {
								this.startHideMenuThread();
							} else {
								if (
									this.indexActive !==
									actionMenu.settingBranch.key
								) {
									this.startHideMenuThread();
								}
							}
						}, 10000);
					}
				}
			);
		}
	};
	onMouseLeaveControlBar = (event) => {
		this.isMouseEnter = false;
		if (!this.isActive) {
			this.startHideMenuThread();
		} else {
			if (this.indexActive !== actionMenu.settingBranch.key) {
				this.startHideMenuThread();
			}
		}
	};
	startHideMenuThread() {
		this.closeMenuTimeout = setTimeout(() => {
			let { isShowMenu } = this.state;
			if (isShowMenu) {
				this.setState(
					{
						isShowMenu: false,
					},
					() => {
						this.hideMenu();
					}
				);
			}
		}, 200);
	}
	showMenu() {
		let menu = document.getElementById("action-menu-id");
		if (menu) {
			menu.style.right = "46px";
		}
	}
	hideMenu() {
		let menu = document.getElementById("action-menu-id");
		if (menu) {
			menu.style.right = "-240px";
		}
	}
	onChangeAction = (key) => {
		this.isActive = true;
		this.indexActive = key;
		this.props.onChangeAction(key);
	};

	render() {
		return (
			<Bound onMouseEnter={this.onMouseEnterControlBar}>
				<ActionPopupContainer
					disconnectInter={this.props.disconnectInter}
					onClosePopup={this.onMouseLeaveControlBar}
					disconnectBranch={this.props.disconnectBranch}
					dataCounter={this.props.dataCounter}
					branch={this.props.branch}
					ref={(ActionMenuContainer) =>
						(this.actionMenuContainer = ActionMenuContainer)
					}
					isConnected={this.props.isConnected}
					onActive={this.onChangeAction}
				/>
			</Bound>
		);
	}
}
