import Axios from "axios";
import { host } from "../host";
import * as types from "./const";

const apiConnectLCD = host + "lcd/connect";
const apiGetDetailsLCD = host + "lcd/get-details";
const apiDisconnectLCD = host + "lcd/disconnect";

export const getDetailsLCD = (DeviceMac) => async (dispatch) => {
	localStorage.removeItem("dataBranch");
	const queryDataFormat = JSON.stringify({
		DeviceMac,
	});

	try {
		const res = await Axios.post(apiGetDetailsLCD, queryDataFormat);
		const { data } = res;
		if (data.Code === 0) {
			localStorage.setItem("dataBranch", JSON.stringify(data));
			dispatch({
				type: types.CONNECT_LCD,
				data,
			});
		}
	} catch (err) {
		console.dir(err);
	}
};

export const connectLcdAction = (connectID, pass) => async (
	dispatch,
	getState
) => {
	const store = getState();
	const { commonReducer } = store;

	const queryDataFormat = JSON.stringify({
		ConnectID: connectID,
		Pwd: pass,
		DeviceMac: commonReducer.clientName,
	});
	try {
		const res = await Axios.post(apiConnectLCD, queryDataFormat);
		// console.log(res);
		const { data } = res;
		if (data) {
			localStorage.setItem("dataBranch", JSON.stringify(data));
			dispatch({
				type: types.CONNECT_LCD,
				data: data,
			});
		}
	} catch (err) {
		console.dir(err);
		dispatch({
			type: types.CONNECT_LCD_ERR,
			err,
		});
	}
};

export const disconnectLcdAction = (ID) => async (dispatch) => {
	const queryDataFormat = JSON.stringify({
		ID: ID,
	});
	try {
		const res = await Axios.post(apiDisconnectLCD, queryDataFormat);
		// console.log(res);
	} catch (err) {
		console.log(err);
	}
};
