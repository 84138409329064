import React, { Component } from "react";
import styled from "styled-components";
import ic_three_next from "./../../images/three-next.png";
const Bound = styled.div`
	display: flex;
	height: 12vh;
	font-family: SVN-Gilroy;
	background: ${(props) => (props.index % 2 ? "#98dcf9" : "#cbedfc")};
	align-items: center;

	font-weight: bold;
	@media screen and (min-width: 1801px) {
		font-size: 120px;
	}
	@media screen and (min-width: 1367px) and (max-width: 1800px) {
		font-size: 70px;
	}
	@media screen and (max-width: 1366px) {
		font-size: 60px;
	}
	.ticky-numb {
		display: flex;
		color: #005993;
		flex: 1;
		justify-content: center;
		align-items: center;
	}
	.counter {
		display: flex;
		color: #d71249;
		flex: 1;
		justify-content: center;
		align-items: center;
	}
	.next {
		display: flex;
		align-items: center;
		justify-content: center;
		img {
			height: 100%;
		}
	}

	@keyframes myType {
		0% {
			opacity: 1;
		}
		50% {
			opacity: 0.4;
		}
		100% {
			opacity: 1;
		}
	}
	.item-bound {
		display: flex;
		width: 100%;
		align-items: center;
		@media screen and (min-width: 1920px) {
			margin-top: 5px;
		}
	}
`;

export default class TickyRowV2 extends Component {
	isCheck = false;
	n(n) {
		if (n > 0) {
			return n > 9 ? "" + n : "0" + n;
		} else return "00";
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.item !== prevProps.item) {
			if (this.props.item.time) {
				this.checkActive(this.props.item.time, this.props.index);
			}
		}
	}
	checkActive(time, index) {
		let isSet = false;
		let delay = 50;
		let rowActive = document.getElementById("item_" + index);

		this.activeInterval = setInterval(() => {
			let now = new Date().getTime();
			let sub = now - time;
			if (sub < 10000) {
				if (!isSet) {
					if (rowActive) {
						rowActive.style.animation = "myType 1s infinite";
						isSet = true;
						delay = 1000;
					}
				}
			} else {
				clearInterval(this.activeInterval);
				rowActive.style.animation = "unset";
			}
		}, delay);
	}
	render() {
		const { item, index } = this.props;

		return (
			<Bound index={index}>
				<div id={"item_" + index} className="item-bound">
					{item && item.TicketNumber !== undefined && (
						<span className={"ticky-numb"}>
							{item.TicketNumber}
						</span>
					)}
					{item && item.CounterNumber && (
						<div className={"next"}>
							<img src={ic_three_next} alt="next" />
						</div>
					)}
					{item && item.CounterNumber && (
						<span className={"counter"}>
							{this.n(item.CounterNumber)}
						</span>
					)}
				</div>
			</Bound>
		);
	}
	componentWillUnmount() {
		clearInterval(this.activeInterval);
	}
}
