import Axios from "axios"
import { host } from '../host'
import * as types from './const'

const apigetConnectToken = host + 'lcd/get-connect-token'

export const getConnectTokenAction = (LcdID) => async dispatch => {
  
  const queryDataFormat = JSON.stringify({
    LcdID: LcdID
  })
  try {
    const res = await Axios.post(apigetConnectToken, queryDataFormat)
    // console.log(res)
    const { data } = res
    if (data) {
      dispatch({
        type: types.GET_CONNECT_TOKEN,
        data: data
      })
    }
  }
  catch (err) {
    console.dir(err);
    dispatch({
      type: types.GET_CONNECT_TOKEN_ERR,
      err
    })
    if(err.response&&err.response.status===403){
      dispatch({
        type: types.LOGOUT_LCD
      })
    }
  }
}

