import * as types from '../action/const'

const initialState = {
    clientName: ''
}

export default function commonReducer(state = initialState, action) {
    switch (action.type) {
        case types.GET_CLIENT_NAME: {
            const { clientName } = action
            return {
                ...state,
                clientName
            }
        }

        default:
            return state
    }
}