import React, { Component } from "react";
import styled from "styled-components";
// import { listVN } from '../../data/dataAudio'
import { popupType } from "../../data/mockData";
import PopupCouterSuccess from "./PopupCouterSuccess";
import PopupDisconnect from "./PopupDisconnect";
import PopupRestoreData from "./PopupRestoreData";
import PopupLogout403 from "./PopupLogout";
const Bound = styled.div`
	width: 100%;
	min-height: 100vh;
	background: rgba(0, 0, 0, 0.6);
	position: absolute;
	top: 0;
	left: 0;
	z-index: 10;
`;

export default class PopupContainer extends Component {
	constructor(props) {
		super(props);
		this.isMouseEnterPopup = false;
		this.state = {
			content: null,
		};
	}
	componentDidMount() {
		this.getPopupContent(this.props.popupRef);
	}
	componentWillReceiveProps(nextProps) {}
	componentWillUnmount() {
		if(this.timer) clearTimeout(this.timer)
	}
	onClosePopup = () => {
		this.props.onClosePopup();
	};
	getPopupContent(popupRef) {
		let content = null;
		switch (popupRef.type) {
			case 2: {
				content = (
					<PopupDisconnect 
						onClosePopup={this.onClosePopup} 
					/>
				);
				if(this.timer) clearTimeout(this.timer)
				this.timer = setTimeout(() => {
					this.onClosePopup()
				}, 5000);
				break;
			}
			case 4: {
				content = (
					<PopupDisconnect
						count={true}
						onClosePopup={this.onClosePopup}
					/>
				);
				if(this.timer) clearTimeout(this.timer)
				this.timer = setTimeout(() => {
					this.onClosePopup()
				}, 5000);
				break;
			}
			case 3: {
				content = (
					<PopupRestoreData 
						onClosePopup={this.onClosePopup} 
					/>
				);
				break;
			}
			// case 5: {
			// 	content = (
			// 		<PopupDisconnect
			// 			content={"Vui lòng kết nối và tải lại trang!"}
			// 			onClosePopup={this.onClosePopup}
			// 		/>
			// 	);
			// 	break;
			// }
			case 6: {
				content = (
					<PopupLogout403 
						onClosePopup={this.onClosePopup} 
					/>
				);
				break;
			}
			default:
				break;
		}
		this.setState({ content });
	}

	render() {
		return (
			<Bound
				onMouseDown={() => {
					if (!this.isMouseEnterPopup) {
						this.onClosePopup();
					}
				}}
			>
				<div
					onMouseEnter={() => {
						this.isMouseEnterPopup = true;
					}}
					onMouseLeave={() => {
						this.isMouseEnterPopup = false;
					}}
				>
					{this.state.content}
				</div>
			</Bound>
		);
	}
}
