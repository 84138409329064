import React, { Component } from 'react'
import styled from 'styled-components'
import ic_notification from '../../images/ic_notification.svg'
import { connect } from 'react-redux'
import { disconnectLcdAction } from '../../action/connectLcdAction'
//
import CoundownNumber from './CountDown'
const Bound = styled.div`
  background: #fff;
    width: 305px;
    height: 216px;
    border-radius: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    .icon-x{
    
    }
    h2{
      font-family: SVN-Gilroy;
      font-size: 16px;
      font-weight: bold;
      line-height: 1.13;
      color: rgb(34, 34, 34);
    }
    h3{
      font-family: SVN-Gilroy;
      font-size: 15px;
      font-weight: bold;
      color: rgb(34, 34, 34);
    }
    .btn-confirm{
      width: 108px;
      height: 36px;
      background-color: rgb(0, 89, 147);
      display: flex;
      -webkit-box-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      align-items: center;
      cursor: pointer;
      border-radius: 18px;
      outline: none;
      border-width: initial;
      border-style: none;
      border-color: initial;
      border-image: initial;
      cursor: pointer;
      font-family: SVN-Gilroy;
      font-size: 14px;
      font-weight: bold;
      text-transform: uppercase;
      color: rgb(255, 255, 255);
    }
`

class PopupDisconnect extends Component {
  onClose = () => {

    this.props.onClosePopup()
  }
  getTime = time => {
    if (time === 0) {
      this.props.onClosePopup()
    }

  }
  render() {
    const {count, content} = this.props
    return (
      <Bound >
        <div className='icon-x'>
          <img src={ic_notification} alt='ic_notification' />
        </div>
        <h2>Không thể kết nối được với hệ thống{content?'.':'!'}</h2>
        {
          content &&
          <h3>{content}</h3>
        }
        {count ?
          <CoundownNumber time={10} running={false} getTime={this.getTime} />
          :
          <button className='btn-confirm' onClick={() => this.onClose()}>XÁC NHẬN</button>

        }
      </Bound>
    )
  }
}
const mapDispathToProps = dispatch => {
  return {

    disconnectLcdAction: (ID) => dispatch(disconnectLcdAction(ID)),

  }
}
const mapStateToProps = state => {
  return ({

  })
}

export default connect(mapStateToProps, mapDispathToProps)(PopupDisconnect)
