import * as types from '../action/const'

const initialState = {
    CounterNumber: '',
    TicketNumber: ''
}

export default function getServiceTicketReducer(state = initialState, action) {
    
    switch (action.type) {
        case types.GET_SERVICE_TICKET:
            return {
                ...state,
                CounterNumber: action.data.CounterNumber,
                TicketNumber: action.data.TicketNumber
            }
        case types.GET_SERVICE_TICKET_ERR:
            return {
                ...state,
                err: 'ERR',
                CounterNumber: '',
                TicketNumber: ''
            }
        default:
            return state
    }
}